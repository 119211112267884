import React from 'react';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import { ENV } from './env';
import { loadFromCookies, loadFromLocalStorage, removeFromCookies, saveToCookies } from './utils';
import { useNavigate } from 'react-router-dom';

import PATH from 'src/constants/pathes';
import { STORAGE_KEYS } from 'src/constants/constants';
import i18n from 'src/utils/translate/config';

export const BASE_URL = ENV.IS_PRODUCTION
  ? 'https://royalhealthcare.asia/api/'
  : 'https://royalhealthcare.asia/dev/api/';
// : 'http://localhost:8088/';

axios.defaults.timeout = 120000; // 120s
axios.defaults.baseURL = BASE_URL;

// Handle token refresh & queue pending requests
type failedQueueTypes = {
  reject: (error: Error) => void;
  resolve: (value?: unknown) => void;
};

export type AxiosConfigWithInterceptedSetting = AxiosRequestConfig & {
  _retry?: boolean;
  _isSkipCommonError?: boolean;
  _isSkipAdditionlAuthError?: boolean;
  url?: string;
};

// token update process handling
// See https://gist.github.com/Godofbrowser/bf118322301af3fc334437c683887c5f
let isTokenRefreshing = false;
let failedQueue: failedQueueTypes[] = [];

const processQueue = (error: Error | null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve();
    }
  });

  failedQueue = [];
};

export const HttpInterceptor: React.FC = props => {
  const navigate = useNavigate();

  axios.interceptors.request.use(
    config => {
      const accessToken = loadFromCookies('access_token', true);
      const currentLanguage = i18n.language;
      if (config.headers) {
        config.headers['Accept-Language'] = currentLanguage || 'vi';

        if (accessToken) config.headers['Authorization'] = `Bearer ${accessToken}`;
      }

      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (response: AxiosResponse<any>) => {
      return response;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (error: AxiosError<any>) => {
      const originalRequest = error.config as AxiosConfigWithInterceptedSetting;
      let status = error.response ? error.response.status : error.code;
      status = status === 'ECONNABORTED' ? 'timeout' : status;
      const errStatus: string = (status ? status : '').toString();

      if (errStatus === '401') {
        const accessToken = loadFromCookies('access_token', true);

        if (!accessToken) {
          navigate(PATH.LOGIN_PAGE);
          return;
        }

        if (originalRequest._retry) {
          return Promise.reject(error);
        }

        if (isTokenRefreshing) {
          return new Promise((resolve, reject) => {
            failedQueue.push({
              resolve,
              reject,
            });
          })
            .then(() => {
              return axios(originalRequest);
            })
            .catch(() => {
              return Promise.reject(error);
            });
        }

        originalRequest._retry = true;
        isTokenRefreshing = true;

        return new Promise((resolve, reject) => {
          const userId = loadFromLocalStorage(STORAGE_KEYS.USER_ID);

          axios
            .post(`${BASE_URL}checktoken/${userId}`, { token: accessToken })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then(({ data }: any) => {
              if (data.status === 200) {
                saveToCookies({ name: 'access_token', value: data.token });
                setTimeout(() => {
                  processQueue(null);
                  resolve(axios(originalRequest));
                }, 100);
              } else {
                removeFromCookies('access_token');
                navigate(PATH.LOGIN_PAGE);
              }
            })
            .catch(err => {
              processQueue(err);
              reject(err);
            })
            .finally(() => {
              isTokenRefreshing = false;
            });
        });
      } else if (errStatus === '500') {
        navigate(PATH['ERROR_500_PAGE']);
      }

      return Promise.reject(error);
    }
  );

  return <>{props.children}</>;
};
